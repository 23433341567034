$black: #000;
$white: #fff;

/////////////// primary brand colors

$sunset-9: #f5b335;
$sunset-8: #f6bb4a;
$sunset-7: #f7c25d;
$sunset-6: #f8ca72;
$sunset-5: #f9d186;
$sunset-4: #fad99a;
$sunset-3: #fbe1ae;
$sunset-2: #fce8c2;
$sunset-1: #fdf0d7;
$sunset-0: #fef7ea;

$cool-pavement-11: #000d16;
$cool-pavement-10: #001c30;
$cool-pavement-9: #002b49;
$cool-pavement-8: #1a405c;
$cool-pavement-7: #33556d;
$cool-pavement-6: #4d6b80;
$cool-pavement-5: #668092;
$cool-pavement-4: #7f94a4;
$cool-pavement-3: #99aab6;
$cool-pavement-2: #b2bfc8;
$cool-pavement-1: #ccd5db;
$cool-pavement-0: #e5e9ec;

/////////////// supporting grays

$iron-12: #050505;
$iron-11: #191c1b;
$iron-10: #2e3432;
$iron-9: #3d4543;
$iron-8: #505856;
$iron-7: #636a69;
$iron-6: #777d7c;
$iron-5: #8a8f8e;
$iron-4: #9da2a1;
$iron-3: #b1b5b4;
$iron-2: #c4c7c6;
$iron-1: #d8dad9;
$iron-0: #ebecec;

$alloy-9: #cfd3d3;
$alloy-8: #d4d7d7;
$alloy-7: #d9dbdc;
$alloy-6: #dee0e0;
$alloy-5: #e2e4e5;
$alloy-4: #e7e9e9;
$alloy-3: #eceded;
$alloy-2: #f1f2f2;
$alloy-1: #f5f6f6;
$alloy-0: #fafafb;

/////////////// supporting colors

$cold-steel-9: #5b89b4;
$cold-steel-8: #6b95bc;
$cold-steel-7: #7ca1c3;
$cold-steel-6: #8cadcb;
$cold-steel-5: #9cb8d2;
$cold-steel-4: #adc4d9;
$cold-steel-3: #bdd0e1;
$cold-steel-2: #cddbe8;
$cold-steel-1: #dee7f0;
$cold-steel-0: #eef3f7;

$cerulean-9: #4b858e;
$cerulean-8: #5d929a;
$cerulean-7: #6f9ea5;
$cerulean-6: #81aab0;
$cerulean-5: #93b6bb;
$cerulean-4: #a5c2c6;
$cerulean-3: #b7ced2;
$cerulean-2: #c9dadd;
$cerulean-1: #dbe7e8;
$cerulean-0: #edf3f3;

$diode-9: #87d1e6;
$diode-8: #94d6e9;
$diode-7: #9fdbeb;
$diode-6: #abdfee;
$diode-5: #b7e4f0;
$diode-4: #c3e8f3;
$diode-3: #cfedf5;
$diode-2: #dbf1f8;
$diode-1: #e7f6fa;
$diode-0: #f3fafc;

$new-grass-9: #45a041;
$new-grass-8: #58aa55;
$new-grass-7: #6ab367;
$new-grass-6: #7dbd7b;
$new-grass-5: #8fc68d;
$new-grass-4: #a2d0a0;
$new-grass-3: #b4d9b3;
$new-grass-2: #c7e2c6;
$new-grass-1: #daecd9;
$new-grass-0: #ecf5ec;

$lizard-9: #b8be14;
$lizard-8: #bfc52c;
$lizard-7: #c6cb43;
$lizard-6: #cdd25b;
$lizard-5: #d4d872;
$lizard-4: #dbde89;
$lizard-3: #e3e5a1;
$lizard-2: #eaebb8;
$lizard-1: #f1f2d0;
$lizard-0: #f8f8e7;

$bumblebee-9: #fed925;
$bumblebee-8: #fedd3c;
$bumblebee-7: #fee051;
$bumblebee-6: #fee467;
$bumblebee-5: #fee87c;
$bumblebee-4: #ffec92;
$bumblebee-3: #fff0a8;
$bumblebee-2: #fff3bd;
$bumblebee-1: #fff7d3;
$bumblebee-0: #fffbe9;

$hazelnut-9: #b78b1e;
$hazelnut-8: #be9735;
$hazelnut-7: #c5a24b;
$hazelnut-6: #cdae62;
$hazelnut-5: #d4ba78;
$hazelnut-4: #dbc58e;
$hazelnut-3: #e2d1a5;
$hazelnut-2: #e9dcbb;
$hazelnut-1: #f1e8d2;
$hazelnut-0: #f8f3e8;

$alert-9: #db6015;
$alert-8: #df702d;
$alert-7: #e28044;
$alert-6: #e6905c;
$alert-5: #e99f73;
$alert-4: #edaf8a;
$alert-3: #f1bfa1;
$alert-2: #f4cfb8;
$alert-1: #f8dfd0;
$alert-0: #fbefe7;

$apple-9: #bc2f2c;
$apple-8: #c34441;
$apple-7: #c95956;
$apple-6: #d06e6b;
$apple-5: #d78280;
$apple-4: #dd9795;
$apple-3: #e4acaa;
$apple-2: #ebc0bf;
$apple-1: #f2d5d5;
$apple-0: #f8eae9;
