$zindex-sentry: 1100;

//Sentry Widget Styling
body {
  .sentry-error-embed-wrapper {
    z-index: $zindex-sentry;

    .sentry-error-embed {
      background: $body-bg;

      * {
        font-family: unset;
      }

      h2 {
        color: $body-color;
        font-size: $h2-font-size;
      }

      header {
        // .sentry-error-embed-wrapper p
        // subheading this does not have any ids or classes
        p {
          color: $text-muted;
          font-size: $font-size-base;
        }
      }

      .form-field {
        // input labels
        label {
          color: $input-color;
          font-size: $font-size-base;
          font-weight: $font-weight-normal;
          line-height: $line-height-base;
          text-transform: unset;
        }

        // input text
        input,
        textarea {
          @include font-size($form-text-font-size);
          background: $input-bg;
          color: $input-color;
          font-style: $form-text-font-style;
          font-weight: $form-text-font-weight;
          line-height: $line-height-base;
          margin-top: $form-text-margin-top;
          padding: $input-padding-y $input-padding-x;

          &::placeholder {
            color: $input-placeholder-color;
          }
        }

        input {
          height: $form-floating-height;
          line-height: $form-floating-line-height;
        }

        // textarea
        textarea {
          height: $form-floating-height;
        }
      }

      .form-submit {
        button {
          @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
          @include transition($btn-transition);

          // submit button
          &.btn {
            @include button-variant($primary, $primary);
          }
          // close button
          &.close {
            @include button-variant(transparent, transparent, $link-color);
          }
        }

        .powered-by {
          color: $text-muted;

          a {
            color: $link-color;
            top: 0;
          }
        }
      }

      // margin after alert
      .error-wrapper p:last-child {
        margin-bottom: $alert-margin-bottom;
      }

      // error alert
      .message-error {
        $alert-background: shift-color($danger, $alert-bg-scale);
        $alert-border: shift-color($danger, $alert-border-scale);
        $alert-color: shift-color($danger, $alert-color-scale);
        @include alert-variant($alert-background, $alert-border, $alert-color);
        @include border-radius($alert-border-radius);

        border: $alert-border-width solid transparent;
        padding: $alert-padding-y $alert-padding-x;
      }

      // success alert
      .message-success {
        $alert-background: shift-color($success, $alert-bg-scale);
        $alert-border: shift-color($success, $alert-border-scale);
        $alert-color: shift-color($success, $alert-color-scale);
        @include alert-variant($alert-background, $alert-border, $alert-color);
        @include border-radius($alert-border-radius);

        border: $alert-border-width solid transparent;
        padding: $alert-padding-y $alert-padding-x;
      }
    }
  }
}
