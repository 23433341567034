@import 'colors';

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';

// 2. Include any default variable overrides here
// for bootstrap variables see: https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

$gray-100: $alloy-1;
$gray-200: $alloy-2;
$gray-300: $iron-3;
$gray-400: $iron-4;
$gray-500: $iron-5;
$gray-600: $iron-6;
$gray-700: $iron-8;
$gray-800: $iron-10;
$gray-900: $iron-11;

$primary: $cool-pavement-9;
$secondary: $iron-8;
$success: $new-grass-9;
$info: $diode-9;
$warning: $bumblebee-9;
$danger: $apple-9;

$light: $iron-12;
$dark: $iron-9;

$white: #fff;
$black: #000;

$body-color: $iron-0;
$body-bg: $iron-11;

$component-active-color: $white;
$component-active-bg: $primary;

$dropdown-bg: $iron-9;
$dropdown-color: $white;

$input-disabled-bg: $gray-800;

$list-group-color: $gray-100;
$list-group-bg: $gray-800;
$list-group-border-color: rgba($black, 0.125);

$list-group-hover-bg: $gray-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;

$list-group-action-color: $gray-700;
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-200;

$form-select-indicator-color: $gray-200;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");

$input-group-addon-bg: $gray-800;

$modal-content-bg: $iron-10;

$pagination-color: $gray-100;
$pagination-bg: $gray-800;
$pagination-border-color: $gray-300;

$pagination-focus-color: $white;
$pagination-focus-bg: $dark;

$pagination-hover-color: $white;
$pagination-hover-bg: $dark;
$pagination-hover-border-color: $gray-300;

$pagination-active-border-color: $gray-300;

$pagination-disabled-bg: $body-bg;

$text-muted: $iron-3;

//////////// tables
$table-color: $body-color;
$table-bg: transparent;
$table-accent-bg: transparent;

$table-striped-color: $table-color;
$table-striped-bg-factor: 0.05;
$table-striped-bg: rgba($black, $table-striped-bg-factor);

$table-active-color: $table-color;
$table-active-bg-factor: 0.1;
$table-active-bg: rgba($black, $table-active-bg-factor);

$table-hover-color: $table-color;
$table-hover-bg-factor: 0.8;
$table-hover-bg: rgba($black, $table-hover-bg-factor);

$table-border-factor: 0.1;

$table-group-separator-color: currentColor;

$table-caption-color: $text-muted;

$table-bg-scale: 70%;

// scss-docs-start table-loop
$table-variants: (
  'primary': shift-color($primary, $table-bg-scale),
  'secondary': shift-color($secondary, $table-bg-scale),
  'success': shift-color($success, $table-bg-scale),
  'info': shift-color($info, $table-bg-scale),
  'warning': shift-color($warning, $table-bg-scale),
  'danger': shift-color($danger, $table-bg-scale),
  'light': $light,
  'dark': $dark,
);

// Accordion

$accordion-color: $body-color;
$accordion-bg: $body-bg;
$accordion-border-color: rgba($black, .125);

$accordion-button-color: $accordion-color;
$accordion-button-bg: $accordion-bg;
$accordion-button-active-bg: $component-active-bg;
$accordion-button-active-color: $white;

$accordion-icon-color: $accordion-button-color;
$accordion-icon-active-color: $accordion-button-active-color;

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

// 3. Include remainder of required Bootstrap stylesheets
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'theme-dark-mixins';

// TODO: Simplified when https://github.com/twbs/bootstrap/issues/34756 is fixed (pending release of Bootstrap 5.2.0)
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'text');
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'bg');

// set link color
$link-color: $cool-pavement-2;

// enable browser / system dark mode
// DO NOT USE @media (prefers-color-scheme: dark) to allow user settings to override browser preference
.theme-dark {
  // sets native date picker theme; maybe other things ¯\_(ツ)_/¯
  color-scheme: dark;

  // root variables
  @each $color, $value in $colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  @each $color, $value in $grays {
    --#{$variable-prefix}gray-#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors-rgb {
    --#{$variable-prefix}#{$color}-rgb: #{$value};
  }

  --#{$variable-prefix}white-rgb: #{to-rgb($white)};
  --#{$variable-prefix}black-rgb: #{to-rgb($black)};
  --#{$variable-prefix}body-color-rgb: #{to-rgb($body-color)};
  --#{$variable-prefix}body-bg-rgb: #{to-rgb($body-bg)};

  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-bg: #{$body-bg};

  // special cases
  .text-light {
    color: $white !important;
  }

  .nav-tabs .nav-link {
    color: $white;

    &.active {
      color: $primary;
    }
  }

  // links
  a {
    color: $link-color;

    &:hover {
      color: shift-color($link-color, $link-shade-percentage);
    }
  }

  // borders
  .border {
    border: 1px solid #{$gray-800} !important;
  }
  .border-bottom {
    border-bottom-color: #{$iron-11} !important;
  }
  .border-top {
    border-top-color: #{$gray-800} !important;
  }

  // cards
  .card {
    background-color: #{$gray-800};
  }

  // buttons
  @each $color, $value in $theme-colors {
    .btn-#{$color} {
      @include button-variant($value, $value);
    }
  }
  // default for buttons on the softphone that don't specify a variant
  .btn {
    color: $body-color;
  }

  @each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
      @include button-outline-variant-dark($value);
    }
  }

  // breadcrumbs
  .breadcrumb-item {
    + .breadcrumb-item {
      &::before {
        color: $breadcrumb-divider-color;
      }
    }

    &.active {
      color: $breadcrumb-active-color;
    }
  }

  // dropdown menu
  .dropdown-menu {
    background-color: $dropdown-bg;
    color: $dropdown-color;
  }

  .dropdown-item {
    color: $white;

    &:hover, &:focus {
      color: $white;
      background-color: $iron-8;
    }

    &.disabled {
      color: $iron-3;
    }
  }

  .dropdown-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.25);
  }

  .dropdown-header {
    color: $iron-3;
  }

  // forms
  .form-control,
  .form-select {
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    color: $input-color;

    &:focus {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
    }

    &:disabled,
    &[readonly] {
      background-color: $input-disabled-bg;
      border-color: $input-disabled-border-color;
    }
  }

  .form-select {
    background-image: escape-svg($form-select-indicator);
  }

  // form autocomplete
  .autocomplete {
    .form-control {
      background-image: escape-svg($form-select-indicator);
    }
  }

  // form input groups
  .input-group-text {
    background-color: $input-group-addon-bg;
    border-color: $input-group-addon-border-color;
    color: $input-group-addon-color;
  }

  // list group
  .list-group-item {
    color: $list-group-color;
    background-color: $list-group-bg;
    border-color: $list-group-border-color;

    &.disabled,
    &:disabled {
      color: $list-group-disabled-color;
      background-color: $list-group-disabled-bg;
    }

    &.active {
      color: $list-group-active-color;
      background-color: $list-group-active-bg;
      border-color: $list-group-active-border-color;
    }
  }

  // modal
  .modal-content {
    background-color: $modal-content-bg;
    box-shadow: 0.5rem 0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.25);
  }

  .modal-header .btn-close {
    filter: invert(1);
  }

  // pagination
  .page-link {
    background-color: $pagination-bg;
    border-color: $pagination-border-color;
    color: $pagination-color;

    &:hover {
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border-color;
      color: $pagination-hover-color;
    }

    &:focus {
      background-color: $pagination-focus-bg;
      color: $pagination-focus-color;
      outline: $pagination-focus-outline;
    }
  }

  .page-item {
    &.active .page-link {
      @include gradient-bg($pagination-active-bg);
      border-color: $pagination-active-border-color;
      color: $pagination-active-color;
    }

    &.disabled .page-link {
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border-color;
      color: $pagination-disabled-color;
    }
  }

  // table
  .table {
    --#{$variable-prefix}table-bg: #{$table-bg};
    --#{$variable-prefix}table-accent-bg: #{$table-accent-bg};
    --#{$variable-prefix}table-striped-color: #{$table-striped-color};
    --#{$variable-prefix}table-striped-bg: #{$table-striped-bg};
    --#{$variable-prefix}table-active-color: #{$table-active-color};
    --#{$variable-prefix}table-active-bg: #{$table-active-bg};
    --#{$variable-prefix}table-hover-color: #{$table-hover-color};
    --#{$variable-prefix}table-hover-bg: #{$table-hover-bg};

    color: $table-color;
    border-color: $table-border-color;

    // Highlight border color between thead, tbody and tfoot.
    > :not(:first-child) {
      border-top-color: $table-group-separator-color;
    }
  }

  @each $color, $value in $table-variants {
    @include table-variant($color, $value);
  }

  // toast
  .toast-header {
    background-color: $iron-10;
    color: $iron-4;

    button {
      filter: invert(1);
    }
  }

  // alert
  .alert-primary {
    color: $white;
    background-color: $cool-pavement-11;
    border-color: $cool-pavement-11;
  }

  .alert-secondary {
    color: $white;
    background-color: $gray-800;
    border-color: $gray-700;
  }

  .alert-danger {
    color: $white;
    background-color: $apple-9;
    border-color: $apple-9;
  }

  .alert-success {
    color: $white;
    background-color: $new-grass-9;
    border-color: $new-grass-9;
  }

  // code blocks

  code {
    background-color: $gray-700;
    border-color: $gray-700;
    color: $body-color;
  }

  // utilities

  // shadow
  .shadow-sm {
    box-shadow: 0 0.2rem 0.25rem rgb(0 0 0 / 20%) !important;
  }

  .mark, mark {
    background: $bumblebee-4;
    color: $iron-11;
  }

  a.mark {
    &:hover {
      color: $iron-12;
    }
  }

  // shimmer
  .shimmer {
    // adding two backgrounds doubles the speed, so we're slowing it down
    // this seems like a bug in Chrome to me, but I'm not sure
    animation-duration: 4.4s;
    background: linear-gradient(to right, $cool-pavement-8 8%, $cool-pavement-7 18%, $cool-pavement-8 33%);
  }

  // google maps
  .gm-style {
    .gm-style-iw-c {
      background-color: $iron-10;

      padding: 12px !important;

      .gm-style-iw-d {
        overflow: hidden !important;
      }
    }
    .gm-style-iw-tc::after {
      background-color: $iron-10;
    }
  }

  // accordion
  .accordion-button {
    color: $accordion-button-color;
    background-color: $accordion-button-bg;

    &:not(.collapsed) {
      color: $accordion-button-active-color;
      background-color: $accordion-button-active-bg;
      box-shadow: inset 0 ($accordion-border-width * -1) 0 $accordion-border-color;
    }

    &:focus {
      border-color: $accordion-button-focus-border-color;
      box-shadow: $accordion-button-focus-box-shadow;
    }
  }

  .accordion-item {
    background-color: $accordion-bg;
    border: $accordion-border-width solid $accordion-border-color;
  }
}

@import 'sentry-dark';
