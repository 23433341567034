@import 'colors';

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';

// 2. Include any default variable overrides here
// for bootstrap variables see: https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

$gray-100: $alloy-1;
$gray-200: $alloy-2;
$gray-300: $iron-1;
$gray-400: $iron-3;
$gray-500: $iron-5;
$gray-600: $iron-6;
$gray-700: $iron-7;
$gray-800: $iron-8;
$gray-900: $iron-9;

$primary: $cool-pavement-7;
$secondary: $iron-7;
$success: $new-grass-9;
$info: $diode-9;
$warning: $bumblebee-9;
$danger: $apple-9;
$light: $alloy-2;
$dark: $iron-9;

// We're only loading Roboto weights 300, 400, and 700. Ensure our CSS only uses these weights.
$font-weight-lighter: 300; // default: lighter
$font-weight-light: 300; // default: 300
$font-weight-normal: 400; // default: 400
$font-weight-medium: 400; // default: 500
$font-weight-semibold: 700; // default: 600
$font-weight-bold: 700; // default: 700
$font-weight-bolder: 700; // default: bolder

$font-weight-base: $font-weight-normal; // default: $font-weight-normal
$headings-font-weight: $font-weight-normal; // default: 500
$display-font-weight: $font-weight-light; // default: 300
$lead-font-weight: $font-weight-light; // default: 300

// enable negative spacing
$enable-negative-margins: true;

// badges
$badge-padding-y: 0.25em;

// default equal height cards
$card-height: 100%;

// default font
$font-family-sans-serif: 'Roboto', sans-serif;

// navbar colors
$navbar-dark-color: $alloy-7;
$navbar-dark-hover-color: $alloy-3;

// table colors
$table-group-separator-color: $iron-2;

// line height
$line-height-base: 1.2;

// mark (highlight)
$mark-padding: 0; // must be 0 with $line-height-base = 1
$mark-bg: $bumblebee-2; // highlight with a brand color

// 3. Include remainder of required Bootstrap stylesheets
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// form height - adjusted to match $line-height-base = 1
$form-floating-height: add(3rem, $input-height-border);
$form-floating-input-padding-t: 1.25rem;
$form-floating-input-padding-b: 0.25rem;

$custom-colors: (
  'hazelnut': $hazelnut-9,
  'lizard': $lizard-9,
  'cerulean': $cerulean-9,
  'cold-steel': $cold-steel-9,
  'diode': $diode-9,
  'alert': $alert-9,
  'sunset': $sunset-9,
  'white': $alloy-0,
  'bumblebee': $bumblebee-2,
  'bumblebee-9': $bumblebee-9,
);

// TODO: Simplified when https://github.com/twbs/bootstrap/issues/34756 is fixed (pending release of Bootstrap 5.2.0)
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'text');
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'bg');

// 4. Include Bootstrap
@import '~bootstrap/scss/bootstrap';

// 5. Add additional custom code (that requires Bootstrap vars) here

.text-body {
  color: $body-color;
}

// branded text selection color
::selection {
  background: $bumblebee-4;
  color: $iron-10;
}

// set link color
$link-color: $body-color;

.popover {
  max-width: 360px;
}

.dropdown-toggle::after {
  display: none;
}

// z-index variables already exist; make this one a class
.zindex-popover {
  z-index: $zindex-popover;
}
.zindex-sticky {
  z-index: $zindex-sticky;
}

// IE doesn't play nice with Bootstrap 5's css variables
.unsupported-browser {
  background-color: $body-bg;
  color: $body-color;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  height: 100vh;
  line-height: $line-height-base;
  margin: 0;
  text-align: $body-text-align;

  .container {
    padding-left: $container-padding-x;
    padding-right: $container-padding-x;
  }

  .bg-dark {
    background-color: $dark;
  }

  .text-light {
    color: $light;
  }
}

// copy basic select styles for autocomplete text input
.autocomplete {
  .form-control {
    background-image: escape-svg($form-select-indicator);
    background-position: $form-select-bg-position;
    background-repeat: no-repeat;
    background-size: $form-select-bg-size;
    padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
    // stylelint-disable-next-line property-no-vendor-prefix
    -moz-padding-start: subtract($form-select-padding-x, 3px); // See https://github.com/twbs/bootstrap/issues/32636

    &:disabled {
      background-color: $form-select-disabled-bg;
      border-color: $form-select-disabled-border-color;
      color: $form-select-disabled-color;
    }

    // Remove outline from select box in FF
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $form-select-color;
    }
  }

  .list-group-item {
    cursor: pointer;
  }
}

//hover classes
.hover-show {
  opacity: 0;
}

tr:hover,
a:hover {
  .hover-show {
    opacity: 1;
  }
}

.hover-display-table-row {
  display: none;
}

tr:hover + .hover-display-table-row,
.hover-display-table-row:hover {
  display: table-row;
}

// allow modals to use 90 percent of the viewport's height
.h-90vh {
  height: 90vh;
}

// Google Maps styles must be global
.map .gm-style .shop-card {
  font-size: 1rem;
  font-weight: $font-weight-normal;
  width: 300px;
}

// remove bottom margin from dl's inside tables -- can't do :last-of-type with style jsx
// this is used in the claims search results matched fields column
tr dl dd:last-of-type {
  margin-bottom: 0;
}

.comments-display {
  .list-group-flush .list-group-item {
    padding-top: 0.5rem;

    &:first-of-type {
      padding-top: 0;
    }

    + .list-group-item {
      padding-top: 1rem;
    }

    p {
      margin-bottom: 0.5rem;
    }
  }
}

.markdown {
  // special markdown for Danny
  .text-red {
    color: $red;
  }

  blockquote {
    border-left: 4px solid $gray-300;
    color: $secondary;
    padding: 0 1rem;
  }

  input[type='checkbox'] {
    margin-right: 5px;
  }

  table {
    border: 1px solid $gray-300;
    color: $body-color;
    margin-bottom: 1rem;
    width: 100%;
    thead th {
      border-bottom: 2px solid $gray-300;
      font-weight: $font-weight-bold;
      vertical-align: bottom;
    }

    th,
    td {
      border: 1px solid $gray-300;
      padding: 0.1rem;
    }
  }

  ol ol li {
    list-style-type: lower-latin;
  }
}

// make <code> blocks readable
code {
  background-color: $gray-200;
  border: 1px solid $gray-300;
  border-radius: $border-radius;
  color: $gray-900;
  display: inline-block;
  font-family: $font-family-monospace;
  font-size: 1rem;
  margin: 0 0.125rem;
  padding: 0 0.3125rem;
  word-wrap: break-word;
}

// TODO: scope this with a parent class / move to style jsx with css variables
// hours of operation calendar view
.cal-year {
  display: flex;
  flex-wrap: wrap;
}
.cal-month {
  font-size: 0.875rem;
  flex-basis: 16.666%;
}
.daily-day {
  flex-basis: 10%;
}
.daily-toggle {
  flex-basis: 12%;
}
.cal-td {
  padding: 2px;
}
.reg-closed {
  opacity: 50%;
}
.holiday-open {
  border-bottom: 3px solid $info;
}
.holiday-open-icon {
  color: $info;
}
.holiday-closed {
  opacity: 50%;
  border-bottom: 3px solid $sunset-8;
}
.holiday-closed-icon {
  color: $sunset-8;
}

@media (max-width: 1200px) {
  .cal-month {
    flex-basis: 25%;
  }
  .daily-day {
    flex-basis: 20%;
  }
  .daily-toggle {
    flex-basis: 20%;
  }
}
@media (max-width: 992px) {
  .cal-month {
    flex-basis: 33.333%;
  }
}
@media (max-width: 768px) {
  .daily-day {
    flex-basis: 25%;
  }
  .daily-toggle {
    flex-basis: 25%;
  }
}

// add shadow on modals
.modal-content {
  box-shadow: 0.5rem 0.5rem 1rem -0.5rem;
}

// shimmer loading effect (https://codepen.io/andru255/pen/wvBdxbb)
.shimmer {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: $cool-pavement-1;
  background: linear-gradient(to right, $cool-pavement-0 8%, $cool-pavement-1 18%, $cool-pavement-0 33%);
  background-size: 1200px 100%;

  &.end {
    width: 40%;
  }

  &.input {
    height: calc(3.5rem + 2px);
    margin-bottom: calc(1.25rem + 21px);
  }

  &.button {
    height: calc(2.25rem + 2px);
    width: 20%;
  }

  &.upload {
    height: calc(3rem + 207px);
    margin: 3rem 0;
  }

  &.qr {
    height: 128px;
    width: 128px;
  }

  &.h1 {
    height: 2.5rem;
    margin-bottom: 0.5rem;
    width: 33%;
  }

  &.td {
    height: 1.25rem;
    width: 100%;
    border-radius: 0.25rem;
  }

  &.media {
    height: 200px;
  }

  &.title-line {
    height: 1.5rem;
    width: 100%;
    margin-bottom: 0.75rem;
    border-radius: 1.25rem;
  }

  &.content-line {
    height: 0.5rem;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
  }

  &.content-line-normal {
    height: 1rem;
    border-radius: 0.5rem;
  }

  &.content-line-large {
    height: 3rem;
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 3rem;
  }

  &.display-1 {
    height: 5rem;
    width: 33%;
    border-radius: 3rem;
  }

  &.display-2 {
    border-radius: 3rem;
    height: 4.5rem;
    margin: 0 auto;
    width: 33%;
  }

  &.badge {
    height: 1.5em;
    width: 7em;

    &:empty {
      display: inline-block;
    }
  }

  &.w-10 {
    width: 10%;
  }

  &.w-20 {
    width: 20%;
  }

  &.end,
  &.w-40 {
    width: 40%;
  }

  &.w-80 {
    width: 80%;
  }

  &.fw-100 {
    width: 100px;
  }

  &.tw-3 {
    width: 3em;
  }

  &.tw-5 {
    width: 5em;
  }

  &.tw-7 {
    width: 7em;
  }

  &.tw-8 {
    width: 8em;
  }

  &.tw-10 {
    width: 10em;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

///////////////////////////////////

// modern text text-shimmer effect
.text-shimmer {
  text-align: center;
  color: rgba($white, 0.1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from($body-color),
    to($body-color),
    color-stop(0.5, $white)
  );
  background: gradient(linear, left top, right top, from($body-color), to($body-color), color-stop(0.5, $white));
  background-size: 125px 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-animation-name: text-shimmer;
  animation-name: text-shimmer;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: $body-color;
}

@-webkit-keyframes text-shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@keyframes text-shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

///////////////////////////////////

// list group in a comments-display with an existing background
.bg-light .comments-display .list-group-item {
  background-color: unset;
}

// allow user to copy text from a button styled to look like a link
// phone numbers are often buttons to enable outbound click-to-call
.btn.btn-link {
  user-select: text;
}

@import 'sentry';
