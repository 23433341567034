@import 'colors';

:root {
  // disable bootstrap/reboot's smooth scrolling; it's odd with Next
  // reference: https://github.com/vercel/next.js/issues/20125
  scroll-behavior: auto;
}

body,
#__next {
  min-height: 100vh;
}

#__next {
  display: flex;

  // bootstrap display utilities with !important override [hidden], but we really do want the hidden attribute to work
  // this is nested in #__next because of the way CSS cascades styles
  [hidden] {
    display: none !important;
  }
}

footer {
  font-size: 0.75rem;

  .list-inline-item:not(:last-child) {
    margin-right: 1rem;
  }
}

.list-unstyled > li {
  line-height: 1.25;
}

.hide-empty:empty {
  display: none;
}

// customize user dropdown
.navbar-nav .nav-item.dropdown .dropdown-toggle {
  padding: 0;
}

.dropdown-menu {
  box-shadow: 2px 2px 5px -3px rgba(0, 0, 0, 0.5);
}

// opacity fade in effect
.transition-opacity {
  opacity: 0;
  transition: opacity .3s linear;
}

.opacity-1 {
  opacity: 1;
}

// print styles must go last due to CSS order of importance
@media print {
  :root {
    scrollbar-width: 0;
  }

  body {
    background-color: $white;
    color: $black;
  }

  a,
  .btn.btn-link,
  .text-muted {
    color: $black !important;
  }

  .bg-light {
    background-color: rgba(0, 0, 0, 0.03) !important;
  }

  .card {
    background-color: $white !important;
  }

  .container-fluid {
    padding: 0;
  }

  .table {
    color: $black !important;
    font-size: 0.8rem;
  }

  .theme-dark {
    .list-group-item {
      color: unset;
      background-color: unset;
      border-color: unset;
    }
  }
}
